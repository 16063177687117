<template>
  <div class="container">
    <Carousel :args="args.slide" />
    <TheStarsTwinkled />
    <!-- <div v-if="false"> -->
    <!-- </div> -->
  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
import TheStarsTwinkled from "@/components/activity/the_stars_twinkled.vue";

export default {
  components: {
    Carousel,
    TheStarsTwinkled,
  },
  created() {
    
    this.$api("/official/page/activity").then(({ data }) => {
      this.args = data;
    });

  },
  data() {
    return {
      args: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
}
</style>

