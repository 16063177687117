<template>
  <div class="the_stars_twinked_container">
    <div class="head">
      <div class="main-title">星光闪闪亮<font> / THE STARS TWINKLED</font>
      </div>
    </div>
    <div class="body">
      <div class="categorys">
        <div @click="switchPeriod(key)" :class="{category:true, actived:key == periodIdx} "
          v-for="(item,key) in periods" :key="key">{{item.period}}</div>
      </div>
      <div class="content">
        <div class="slide-pics">
          <el-carousel indicator-position="none" arrow="always" height="100%">
            <el-carousel-item v-for="(pic,key) in info.slide" :key="key">
              <div class="pics">
                <!-- <img class="badge" src="/static/img/activity/hot.png"> -->
                <div class="corner">
                  <img src="/static/img/activity/corner.png">
                  <span>{{key+1}}/{{info.slide.length}}</span>
                </div>
                <img class="pic" :src="pic" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="title">
          {{info.title}}
        </div>
        <div class="extra">
          <div class="time">{{dateFormat(info.issue_time, "y / m / d")}}</div>
          <div class="look_num">
            <img src="/static/img/activity/eye.png">
            {{info.look_num}}
          </div>
        </div>
        <div class="intro">
          {{info.intro}}
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="title">视频 / VIDEO</div>
      <div class="blocks">
        <div class="block" @click="showFullScreenVideo(info.open_video)">
          <div class="video">
            <img class="cover" :src="info.open_cover" />
            <img class="open" src="/static/img/activity/open.png" />
            <div class="desc">
              <div class="desc-title">{{info.open_title}}</div>
              <div class="desc-extra">
                <div class="desc-time">{{dateFormat(info.issue_time, "y / m / d")}}</div>
                <div class="desc-look-num">
                  <img src="/static/img/activity/video_eye.png"> {{info.look_num}}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="badge">
            <img src="/static/img/activity/start.png" />
          </div> -->
        </div>
        <div class="block" @click="showFullScreenVideo(info.end_video)">
          <div class="video">
            <img class="cover" :src="info.end_cover" />
            <img class="open" src="/static/img/activity/open.png" />
            <div class="desc">
              <div class="desc-title">{{info.end_title}}</div>
              <div class="desc-extra">
                <div class="desc-time">{{dateFormat(info.issue_time, "y / m / d")}}</div>
                <div class="desc-look-num">
                  <img src="/static/img/activity/video_eye.png"> {{info.look_num}}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="badge right">
            <img src="/static/img/activity/end.png" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      periodIdx: 0,
      periods: [],
      info: {},
    };
  },
  created() {
    this.$api("/official/page/activity_periods").then(({ data }) => {
      this.periods = data;
      if (this.periods.length) {
        // this.periods[0].period = "最新";
        this.getInfo(this.periods[0].id);
      }
    });
  },
  methods: {
    getInfo(id) {
      this.$api("/official/page/activity_info", {
        id: id,
      }).then(({ data }) => {
        this.info = data;
        this.info.slide = JSON.parse(this.info.slide);
      });
    },
    switchPeriod(idx) {
      this.periodIdx = idx;
      this.getInfo(this.periods[idx].id);
    },
  },
};
</script>

<style lang="scss" scoped>
.the_stars_twinked_container {
  margin: 0 auto;
  width: 1180px;
  .head {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }
  .body {
    margin-top: 50px;

    .categorys {
      display: flex;

      .category {
        user-select: none;
        cursor: pointer;
        width: 135px;
        height: 48px;
        background: #f5f5f5;
        border-radius: 24px;

        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 22px;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: 0.5s;
        &:not(.actived):hover {
          background: #ff6f1b;
          color: white;
          transform: scale(1.05);
        }

        &.actived {
          background: #ff6f1b;
          color: white;
          cursor: default;
        }

        margin-right: 20px;
      }
    }
    .content {
      margin-top: 50px;
      background: rgba($color: #ff6f1b, $alpha: 0.06);
      border-radius: 16px;
      padding: 50px 59px 34px 59px;

      .slide-pics {
        width: 1062px;
        height: 624px;

        ::v-deep.el-carousel {
          height: 100%;
        }

        ::v-deep.el-carousel__arrow--left,
        ::v-deep.el-carousel__arrow--right {
          background-color: rgba(255, 111, 27, 0.5);

          transition: 0.5s;
          &:hover {
            background-color: rgba(255, 111, 27, 1);
          }
        }

        .pics {
          position: relative;

          .badge {
            position: absolute;
            left: 25px;
            top: 20px;

            width: 88px;
            height: 37px;
          }

          .pic {
            width: 1062px;
            height: 624px;
          }

          .corner {
            position: absolute;
            bottom: 0px;
            right: 0px;

            img {
              width: 79px;
              height: 79px;
            }

            span {
              font-size: 29px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 40px;

              position: absolute;
              bottom: 10px;
              right: 2px;
            }
          }
        }
      }
      .title {
        margin-top: 18px;

        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff6f1b;
        line-height: 37px;
      }
      .extra {
        display: flex;
        align-items: center;
        padding-top: 13px;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;

        .time {
          margin-right: 30px;
        }
        .look_num {
          img {
            width: 20px;
            height: 13px;
          }
        }
      }
      .intro {
        padding-top: 21px;
        text-align: justify;
        // width: 817px;
        // height: 72px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
    }
  }

  .foot {
    margin-top: 87px;
    margin-bottom: 53px;
    position: relative;

    width: 1180px;
    height: 430px;
    border: 6px solid rgba($color: #ff6f1b, $alpha: 0.4);
    border-radius: 16px;

    .title {
      // min-width: 335px;
      height: 50px;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff6f1b;
      background-color: #fff;
      line-height: 50px;
      padding: 23px;

      position: absolute;
      left: 45px;
      top: -55px;
    }

    .blocks {
      display: flex;
      margin-top: 48px;
      justify-content: center;

      .block {
        &:first-child {
          margin-right: 40px;
        }

        .video {
          position: relative;
          cursor: pointer;

          .cover {
            width: 525px;
            height: 314px;
          }
          .open {
            position: absolute;
            left: 235px;
            top: 137px;
            width: 40px;
            height: 40px;
          }
          .desc {
            position: absolute;
            bottom: 3px;
            left: 0;
            right: 0;
            height: 77px;
            background: rgba($color: #000000, $alpha: 0.56);

            .desc-title {
              margin: 14px 15px 5px 15px;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 22px;
            }
            .desc-extra {
              display: flex;
              margin: 0 15px;

              justify-content: space-between;

              .desc-time {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 17px;
              }
              .desc-look-num {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 17px;
                img {
                  width: 13px;
                  height: 9px;
                }
              }
            }
          }
        }

        .badge {
          margin-top: 28px;

          img {
            width: 150px;
            height: 58px;
          }
          &.right {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
</style>